import InputText from './InputText';
import React, { FC, forwardRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const InputNumberMasking: FC<NumberFormatProps> = forwardRef<
  any,
  NumberFormatProps
>((props, ref) => (
  <NumberFormat
    mask=""
    {...props}
    getInputRef={ref}
    customInput={InputText}
    type="password"
  />
));

export default InputNumberMasking;
