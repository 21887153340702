import { CartItem } from './CartList';
import { Tit } from './Titles';
import BR from './Typing/BR';
import imgArrowUp from '@/assets/img/arrow_up@2x.png';
import { StepBody, StepHead, ToggleBox } from '@/components/ToggleContainer';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import React, { ImgHTMLAttributes, ReactElement, useState, VFC } from 'react';
import styled from 'styled-components';

interface TitProps {
  titleData: TitleItem[];
}

export interface TitleItem {
  from: string;
  tit: string;
  cnt: number;
  price: string;
}

export interface SelectItem {
  id: string | number;
  tit: string;
  titEn: string;
  price: string;
  cnt: number;
  img: ImgHTMLAttributes<any>['src'];
}

const Small = styled.small`
  display: inline-blokc;
  font-size: 20px;
`;

const ButtonToggle = styled.button<{ isOpen: boolean }>`
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${imgArrowUp});
  position: absolute;
  right: 0;
  top: 30%;
  transform: translate(0, -50%) rotate(180deg);
  ${({ isOpen }) => isOpen && `transform: translate(0, -50%);`}
  transition-duration: 0.6s;
  ${breakpoint(`mobile`)} {
    width: 32px;
    height: 32px;
  }
`;

const ItemList = styled.ul`
  padding-top: 12px;

  li {
    position: relative;
    padding: 24px 0;
    border-bottom: 1px solid #e5e6e8;

    .item {
      display: flex;
      width: 100%;

      .col-img {
        width: 160px;

        ${breakpoint(`tablet`)} {
          width: 110px;
        }

        .img-box {
          padding-top: 100%;
          border: 1px solid #e5e6e8;
        }
      }

      .col-dec {
        width: calc(100% - 160px);
        padding-left: 32px;
        padding-top: 10px;
        line-height: 1;

        ${breakpoint(`tablet`)} {
          width: calc(100% - 110px);
        }

        .tit {
          display: block;
        }

        p {
          margin-top: 10px;
          font-weight: 100;
        }

        .price {
          display: block;
          margin-top: 20px;
        }
      }
    }
  }
`;

const BlueText = styled.span`
  color: #1cabe2;
`;

interface GiftListProps {
  items: CartItem[];
  title: ReactElement;
  defaultShowList?: boolean;
}

const GiftList: VFC<GiftListProps> = ({ items, title, defaultShowList }) => {
  const [showGifts, setShowGifts] = useState(!!defaultShowList);
  const totalPrice = items.reduce(
    (result, item) => result + item.productPrice * item.count,
    0,
  );
  return (
    <ToggleBox>
      <StepHead>
        <Tit
          size="s2"
          css={`
            color: #2d2926;
          `}
        >
          {title}
          <BR only="mobile" />
          <Small>
            {` `}(총 <BlueText>{items.length}</BlueText>개/{` `}
            <BlueText>{totalPrice.toLocaleString()}</BlueText>원)
          </Small>
        </Tit>
        <ButtonToggle
          type="button"
          isOpen={showGifts}
          onClick={() => setShowGifts(!showGifts)}
        >
          <span className="a11y">열기/닫기</span>
        </ButtonToggle>
      </StepHead>
      {showGifts && (
        <StepBody>
          <ItemList>
            {items?.map((row) => (
              <li key={row.productCode}>
                <Link
                  to={`/involve/individual/ig/${row.productCode}`}
                  className="item"
                >
                  <div className="col-img">
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${row.imgProductAttImgNo1_290});
                      `}
                    />
                  </div>
                  <div className="col-dec">
                    <strong className="tit">{row.productName}</strong>
                    <p>{row.productNameEn}</p>
                    <strong className="price">
                      {row.productPrice.toLocaleString()}({row.count}개)
                    </strong>
                  </div>
                </Link>
              </li>
            ))}
          </ItemList>
        </StepBody>
      )}
    </ToggleBox>
  );
};
export default GiftList;
