import styled from 'styled-components';

export const ToggleBox = styled.div``;

export const StepHead = styled.div`
  line-height: 1.2;
  padding-bottom: 29px;
  color: #b7b8ba;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  border-bottom: 1px solid #b7b8ba;
`;

export const StepBody = styled.div`
  display: block;
`;
